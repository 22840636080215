import {checkEventEnded} from '@/mixins/dateSupport.js';
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import FirebaseActions from "@/mixins/FirebaseActions";
import {EventBus} from '@/main';
import {mapActions} from 'vuex';
const playerMixins={
    methods:{
        ...mapActions(['actListContents']),
        //playlist creation related methods:
        getContentType(content){
            if(content.trailerId)return 'T';
            if(content.seriesId)return 'S';
            if(["MOVIE","TVSHOW"].includes(content.contenttype))return 'M';
            if(content.contenttype==='EVENT')return 'E';
            if(content.contenttype==='LIVE')return 'L';
            //default
            return "D";  
          },
        getEpisodesList(content){
            let params = {};
            params.seriesid = content.seriesId;
            console.log(params);
            return this.actListContents(params)
              .then((response) => {
                if (response.error)throw new Error('no contents');

                return response;
              })
        },
        getContentList(content){
            let data = {};
            data.streamcodec = "DASH";
            data.contenttype = content.contenttype;
            data.category = content.category;
            return this.actListContents(data).then((response)=>{
                    if(!response?.length)throw new Error('no contents');
                    return response; 
            });
        },
        getEventsList(content){
            return this.getContentList(content).then(list=>{
                return list.filter((content)=>{
                            let isAvailable=!( ['ANNOUNCED','PREBOOK'].includes(content.premieringstatus));
                            if(!isAvailable)return false;
                            else if(checkEventEnded(content.todate))return false;
                            else return true;
                         })
            })
        },

        //event handlers:

        handleContentLoad({content}){
           this.vLiveSDKContentView({contentid:content.contentId});
        },
        handleContentUnload({content}){
            if(content.live)return;
            if(content.trailerId)return this.TrailerWatchingAnalytics({status: 'success'},content);
            console.log('UNloaded content',content);
            this.vLiveSDKContentWatchTime({contentid:content.contentId}, content.secondsPlayed);
            let firebaseContent={
                watchedduration:content.position,
                contentid:content.contentId,
                trailerid:content.trailerId,
                seriesid:content.seriesId,
                episodenum:content.episodeNo,
                status:(parseInt(content.duration)-content.position)<=9?'COMPLETED':'INPROGRESS',

                title: content.title,
                description:content.description,
                contenttype:content.contenttype,
                category:content.category,
                duration:content.duration,
                posters:content.posters,
                tags:content.tags,
                year:content.year,
                
            }
            console.log(firebaseContent);
            const updatePromise=content.inFirebase?this.updateIntoFirebase(firebaseContent):this.addToFirebase(firebaseContent);
            updatePromise.then(()=>{
                EventBus.$emit("firebaseUpdated");
            })
            this.ContentPlaybackAnaltics({status:'success'},content);
        },

        //analytics:
        TrailerWatchingAnalytics(data,content={}) {
            let Analytics = {
                ContentID: content.contentId,
                orientation: window.screen.orientation.type
            };

            let contentData=( 
                        content.seriesId? 
                            {SeriesID: content.seriesId,
                             SeriesTitle: content.title}
                            :
                            {ContentTitle:content.title} 
                        );
            let statusData={};                    

            if (data.status === 'success') {
                statusData={
                    TrailerID:content.trailerId,
                    TrailerTag:content.trailertag,
                    Duration:content.duration,
                    'Event Label':'Success',
                    PlaybackDuration:content.secondsPlayed
                }
            }
            else if(data.status==='failure'){
                statusData={
                    ErrorCode:data.error.code,
                    ErrorMessage:data.error.reason
                }
            }

            Object.assign(Analytics,contentData,statusData);
      
            this.TrailerWatchingEvent(Analytics);
          },
        ContentPlaybackAnaltics(data,content={}) {
            let Analytics = {
              ContentID: content.contentid,
              Category: content.category,
              Duration: content.duration,
            };
      
            let contentData=( 
                content.seriesId? 
                    {SeriesID: content.seriesId,
                     SeriesTitle: content.title,
                     EpisodeNo:content.episodeNo}
                    :
                    {ContentTitle:content.title} 
                );
      
            let statusData={};                    

            if (data.status === 'success') {
                statusData={
                    PlaybackDuration:content.secondsPlayed,
                    Progress:(content.position/content.duration)*100,
                    Duration:content.duration,
                    'Event Label':'Success'
                }
            }
            else if(data.status==='failure'){
                statusData={
                    ErrorCode:data.error.code,
                    ErrorMessage:data.error.reason,
                    'Event Label':'Failure'
                }
            }
    
            Object.assign(Analytics,contentData,statusData);    
           
            this.ContentPlaybackEvent(Analytics);
          } 

    },
    mixins:[GoogleAnalytics,FirebaseActions]
}

export {playerMixins};