<template>
  <popup :closePopupEvent="closePlayer" v-if="errorData">
      <div class="error-wrapper">
        <div class="title">{{errorData.title}}</div>
        <div class="message">{{errorData.message}}</div>
        <div class="reset-btn">  
          <button
            class="btn-login"
            @click="() => buyable ? buyContent() : closePlayer()"
          >
            {{ buyable ? 'Buy' : 'Close Player' }}
          </button>
        </div>
      </div>  
  </popup>
</template>

<script>
import {EventBus} from '@/main';
import {playerMixins} from '@/mixins/playerMixins';
const errorDataMapper={
    'fallback':{
        title:'Player Error',
        message:'There is an internal error. Please report.'
    },
    0:{
        title:'Player Error',
        message:'Please update the browser or try from a different device.'
    },
    1:{
        title:'Content Invalid',
        message:'Content is not valid' 
    },
    2:{
        title:'Unauthorized Access',
        message:'Please login and try again.'
    },
    3:{
        title:'Content not bought!',
        message:'Please buy the content to watch.'
    },
    4:{
        title:'Player Error',
        message:'Please refresh the page and try again.'
    },
    5:{
        message:'Session Loading Error',
        message:'Please refresh the tab and try again.'
    },
    6:{
        title:'Streaming Error',
        message:'Please update the browser and try again.'
    }
}

const getErrorData = ( { code , reason } ) => {
    const errorData = Object.assign( {},  errorDataMapper[code] ?? errorDataMapper['fallback'] ) ;
    if( reason ) errorData.message = reason ;
    return errorData ;
}

export default {
    props:['error','content'],
    data(){
        return {
            errorData : null,
            buyable : false
        }
    },
    watch:{
        error:{
            immediate: true,
            handler( val ){
                if( !val ) return null;
                this.buyable = val?.code === 3  ;
                this.errorData = getErrorData( val ) ;
            }
        }
    },
    methods:{
        closePlayer(){
            EventBus.$emit('unloadPlayer')
        },
        buyContent(){
            this.closePlayer();
            this.$router.push({
                    name:'Home/Card',
                    params: { contentid : this.content.contentId }
                });
        }
    },
    components:{
         popup: () =>import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue")
    },
    mixins:[playerMixins],
    created(){
        console.log('CALL FROM ERRORMODAL ERROR',this.error,this.content);
        if(!this.content)return;
        if(this.content.trailerId){
            this.TrailerWatchingAnalytics({status:'failure',error:this.error},this.content);
        }
        else if(!this.content.live){
            this.ContentPlaybackAnaltics({status:'failure',error:this.error},this.content);
        }
    }
    
}
</script>

<style lang='scss' scoped>
@import "~sass/modules/_variables";
@import "~sass/modules/_fonts";
    .error-wrapper{
        padding:20px 30px;
        .title, .message{
            color: $clr-white;
            font-size: 1.2rem;
            line-height:1.5rem;
            font-family: $font-family;
            font-weight: $font-weight-semibold;
            margin: 15px 0;
        }
        .message{
            font-size:1rem;
            line-height: 1.2rem;
            margin: 10px 0;
            
        }
    }
</style>