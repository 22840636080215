<template>
   <video-component
      class="main-player"
      ref="videoComponent"
      v-if="playerInstance"
      :customComponents="components"
      :customIcons="icons"
      :customSettings="settings"
      :getItem="getPlayerItem"
      :player="playerInstance"
      :playlist="playlist"
      :closer="closePlayer"
      @playerloaded="loadPlaylist"
      @minimised="handleOverflow"
      
   />
</template>
<script>
import VideoComponent from 'vue-video-controls';
import {createPlayerContent} from 'video-player';
import {mapGetters,mapActions} from 'vuex';
import {playerMixins} from '@/mixins/playerMixins';
import { formatDateString } from '@/mixins/dateSupport';
import firebase from "firebase/app";
import "firebase/database";
import {EventBus} from '@/main';
import ErrorModal from '@/components/Player/errorModal.vue';
import { playerConfig as vendorConfig } from "@/module/vendor-config";
import {fetchToken} from './tokenRequest';


const getPoster=(posters=[])=>{
    const quality = document.body.clientWidth > 900 ? 'HD' : 'SD' ;
    return posters
          .filter( pos => pos.orientation === 'LANDSCAPE' )
          .sort( pos => pos.quality === quality ? -1 : 1 )
          .map(pos=>pos.url);
}

export default {
  props:['content'],
  data(){
    return {
      components:{
        title:()=>import('@/components/Player/playerTitle.vue'),
        errorModal:ErrorModal,
        playpauser:()=>import('@video/src/components/playpause/Playpauser_2.vue'),
        control_bar:()=>import('@video/src/components/controls/ControlBar_2.vue'),
        playlist:()=>import('@video/src/components/playlist/Playlist_2.vue')
      },
      icons:{
        play:'play.svg',
        pause:'pause.svg',
        forward:'forward.svg',
        rewind:'rewind.svg',
      },
      settings:{
        startIndex:0,
        autoPlay:true,
        playlistActive:true,
        posterActive:true,
        borderColor:vendorConfig.color,
        fingerPrintData:null,
        productLogo: vendorConfig.logo,
        slidebar:{
                  progressColor:vendorConfig.color,
                  pointerColor:vendorConfig.color
                }
      },
      playlist:[]
    }
  },
  watch:{
    content(newvalue){
          this.playlist=[this.getPlaylistItem(newvalue)];
          this.$refs.videoComponent?.loadContent?.(this.playlist[0]).then(({loaded,content})=>{
            if(loaded)this.loadPlaylist(content);
          })
    }
  },
  computed:{
      ...mapGetters(["vendorId","subscriberId","ticketResponse","appUser","appConfig","playerInstance"]),
      planQuality(){
        return this.appUser?.plantype==='PREMIUM' && !( new Date()>new Date( formatDateString( this.appUser?.expiryDate ) ) )?3000:480;
      }
  },
  methods:{
      ...mapActions(['actGetContent','actListContents']),
      //method to verify if playlistcontent is valid.
      async fetchPlaylistContent(content){
        try{
            if(!content.source[0]){ //get content data if not present.
              let response=await this.actGetContent(content.contentId);
              if(response?.error)throw new Error(response.error);
              if(response){
                content.source=[response.contentlink];
                content.poster=getPoster(response.posters);
                content.posters = response.posters;
                content.seriesId=response.seriesid;
                content.trailerId=response.trailerid;
                content.episodeNo=response.episodenum;
                content.description=response.description;
                content.contenttype=response.contenttype;
                content.category=response.category;
                content.tags=response.tags;
                content.year=response.year;

              }
            }  
            if(!content.position && !content.live){//get firebase data if not present.
              let firebaseData=await this.getPlaylistFirebaseContent(content);
              if(firebaseData){
                content.position=firebaseData.watchedduration;
                content.inFirebase=true;
              }
            }

            return content;

        }
        catch(e){
          console.log(e);
          return content;
        }
      },
      //get firebase related data for playlistcontent
      getPlaylistFirebaseContent(content){
        const primaryPath=content.seriesId?'series':'content';
        const id=content.seriesId??content.contentId;
        const episodePath=content.seriesId && content.episodeNo?`/episode/${content.contentId}`:'';

        const path = `${this.vendorId}/subscriber/${this.subscriberId}/${primaryPath}/${id}${episodePath}`;
        console.log(path);
        return new Promise((response,reject)=>{
                  firebase
                    .database()
                    .ref(path)
                    .once(
                      "value", 
                      (snapshot) => {
                        if (snapshot.val()) {
                          response( snapshot.val() );
                        }
                        else reject(null);
                      },
                      (error)=>{
                        console.log(error);
                        reject(error);
                      }
                    )

        });

      },
      //called before load to get all playlistcontent details.
      async getPlayerItem(playlistContent){
        let [
            {value:drmToken,reason:drmError},
            {value:syncedContent}
          ]
          =await Promise.allSettled([
            fetchToken(playlistContent),
            this.fetchPlaylistContent(playlistContent)
          ]);
        
        if(drmError){ 
          let code = 2 , reason = drmError?.reason ?? null ;

          switch( drmError?.error ){
            case 3125: code = 3; break ;
            default: code = 2
          }
          syncedContent.source = [] ;
          this.playerInstance.hub.dispatch('error', { code, reason } )
        };

        syncedContent.drmToken=drmToken;
        return createPlayerContent(syncedContent)
      },
      //synchronous method to create playlist item:
      getPlaylistItem(content){
          return {
                contentId: content.contentid??content.trailerid,
                trailerId:content.trailerid,
                seriesId:content.seriesid,
                episodeNo:content.episodenum,
                title: content.title,
                description:content.description,
                contenttype:content.contenttype,
                live:['LIVE','EVENT'].includes(content.contenttype),
                category:content.category,
                duration:content.duration?Number(content.duration):null,
                position:content.watchedduration,
                inFirebase:!!content.watchedduration,
                tags:content.tags,
                year:content.year,
                source: [
                content.contentlink??content.url
                ],
                licenseServer: `${this.appConfig.vendorDetails.drmUrl}${this.vendorId}`,
                poster:getPoster(content.posters),
                posters: content.posters,
                contentQuality:this.getQualityLimit(content),
                isDrmContent:!content.trailerid
            }
      },
      getQualityLimit(content){
        let ticketQuality=0;
        if (Array.isArray(this.ticketResponse) && !this.ticketResponse.error){
            let qualityType=this.ticketResponse.find(ticket=>{
              return content.seriesid?content.seriesid===ticket.seriesid:content.contentid===ticket.contentid
              })?.quality;

            ticketQuality=qualityType==='HD'?3000:480;
        }
        return ticketQuality>this.planQuality?ticketQuality:this.planQuality;
      },
      loadPlaylist(content){
            console.log(content);
            let typeOfContent=this.getContentType(content);
            let fetchList=null;
            //determine typeof fetch method:
            switch(typeOfContent){
              case 'S':
                  fetchList=this.getEpisodesList;
                  break;
              case 'M':
              case 'L':  
                  fetchList=this.getContentList;
                  break;
              case 'E':
                  fetchList=this.getEventsList;
                  break;
              default:
                  fetchList=()=>Promise.reject();
                  
            }

            fetchList(content).then((list)=>{
              if(!list?.length)return;
              //assigning new playlist
              this.playlist=list.map(content=>{          
                  let playlistItem=this.playlist.find(item=>item.contentId===content.contentid);
                  return playlistItem??this.getPlaylistItem(content);
                });   
            }).catch(e=>{
              console.log('no playlist contents',e);
            })
      },
      handleOverflow(minimised){
        if(minimised)document.body.classList.remove('no-overflow');
        else document.body.classList.add('no-overflow');
      },
      closePlayer(){
            EventBus.$emit('unloadPlayer')
      },
      attachListeners(){
        this.playerInstance.hub.listen('content-load',this.handleContentLoad)
        this.playerInstance.hub.listen('content-unload',this.handleContentUnload)
      }
  },
  mixins:[playerMixins],
  created(){
    this.settings.fingerPrintData=vendorConfig.fingerprint?this.subscriberId:null;
    this.playlist.push(this.getPlaylistItem(this.content));
    this.attachListeners();
  },
  mounted(){
    this.handleOverflow(false);
  },
  beforeDestroy(){
    this.handleOverflow(true)
  },
  components:{
    VideoComponent
  }
}
</script>
<style lang="scss" scoped>
.main-player{
   z-index:3000;
   font-family:'Montserrat', sans-serif;
   height:100% !important;
  &:not(.player-minimised){
    position:absolute;
    top:0;
    left:0;
  }
}
</style>
