import firebase from "firebase/app";
import "firebase/database";
import { mapGetters, mapMutations, mapActions } from "vuex";
import appMixins from "@/mixins/appMixins";
import { dateToString } from "@/mixins/dateSupport.js";
// import snackbar from "node-snackbar";
// import GoogleAnalytics from "@/mixins/GoogleAnalytics";

const FirebaseActions = {
  computed: {
    ...mapGetters(["vendorId", "subscriberId"]),
  },
  methods: {
    ...mapMutations(["CommitConfigStore", "CommitUserStore"]),
    ...mapActions(["actGetContent"]),

    getPosterUrl(posters) {
      if (posters && posters.length) {

        let landscapePosters = posters.filter(
          pos => pos.orientation === "LANDSCAPE"
        );
        // if (landscapePosters.length == 0)
        //   landscapePosters = posters.filter(
        //     pos => pos.orientation === "PORTRAIT"
        //   );
        if (landscapePosters.length) {
          let posterIndex = landscapePosters.findIndex(
            pos => pos.quality === "HD"
          );
          if (posterIndex > -1) {
            return landscapePosters[posterIndex].url;
          } else {
            return landscapePosters[0].url;
          }
        }

      }
      return null;
    },

    async addToFirebase(content) {
      console.log("content ", content)
      let _this = this;

      if (!_this.subscriberId) return Promise.resolve();

      if (content.seriesid && (content.seriesid !== content.contentid) && !content.likeDislikeDisplay && !content.inwatchlist) {
         
        let posterurl = this.getPosterUrl(content.posters);
 
        let episode = {
            subscriberid: _this.subscriberId ? _this.subscriberId : "",
            contenttitle: content.title ? content.title : "",
            category: content.category ? content.category : "",
            poster: posterurl ? posterurl : "",
            contenttype: content.contenttype ? content.contenttype : "",
            tags: content.tags ? content.tags : "",
            year: content.year ? content.year : "",
            episodenum: content.episodenum ? content.episodenum : "",
            duration: content.duration ? parseInt(content.duration) : 0,
            description: content.description ? content.description : "",
            contentstatus: "ACTIVE",
            watchlisttimestamp: Math.floor(new Date().getTime() / 1000),
            status: content.status ? content.status : "",
            startdate: dateToString(new Date()),
            watchedduration: content.status === "COMPLETED"? 0 : content.watchedduration
        };

        let seriespath=`${_this.vendorId}/subscriber/${_this.subscriberId}/series/${content.seriesid}`;
        let series=await firebase.database().ref(seriespath).once("value").then(snapshot=>snapshot.val());
        if(series){
          let path=  `${seriespath}/episode/${content.contentid}`;
          return firebase
          .database()
          .ref(path)
          .update(episode);
        }
        else {
          content = await this.actGetContent(content.seriesid);
          content.episode={[content.contentid]:episode};
        }
      }

      let posterFirebase = this.getPosterUrl(content.posters);

      let data = {
        subscriberid: _this.subscriberId ? _this.subscriberId : "",
        contenttitle: content.title ? content.title : "",
        category: content.category ? content.category : "",
        poster: posterFirebase ? posterFirebase : "",
        contenttype: content.contenttype ? content.contenttype : "",
        tags: content.tags ? content.tags : "",
        year: content.year ? content.year : "",
        episodecount: content.episodecount ? content.episodecount : "",
        episode: content.episode ? content.episode : "",
        duration: content.duration ? parseInt(content.duration) : 0,
        description: content.description ? content.description : "",
        contentstatus: "ACTIVE",
        watchlisttimestamp: Math.floor(new Date().getTime() / 1000),
        inwatchlist: content.inwatchlist ? content.inwatchlist : "",
        likedislike: content.likeDislikeDisplay ? content.likeDislikeDisplay : "",
        watchedduration: content.status === "COMPLETED" ? 0 : content.watchedduration ? content.watchedduration : 0,
        status: content.status ? content.status : "",
        startdate: dateToString(new Date())
      };
      if (content.episode) {
        console.log("episode add ", content.episode, typeof (content.episode))
      }
      let pathContent;
      // if (content.contenttype === "SERIES" || content.contenttype === "TVSHOW") {
      //   pathContent = "series"
      // }
      if (content.seriesid || content.contenttype === "SERIES") {
        pathContent = "series"
      }
      else {
        pathContent = "content"
      }
      // Define the path to add to watchlist

      let path = `${_this.vendorId}/subscriber/${_this.subscriberId}/${pathContent}/${content.seriesid ? content.seriesid : content.contentid
        }`;




      if (content.likeDislikeDisplay === "LIKE") {

        _this.vLiveSDKContentLikeDislike(
          content,
          1,
          0
        );
        // this.actFetchLikeCount()
      }

      else if (content.likeDislikeDisplay === "DISLIKE") {

        _this.vLiveSDKContentLikeDislike(
          content,
          0,
          1
        );

        // this.actFetchDislikeCount()
      }
      // else if (!content.watchedduration) {
      // else if (content.likeDislikeDisplay === "NONE") {
      //  textDisplay = "Neither liked or disliked"
      // this.actFetchLikeCount();
      // this.actFetchDislikeCount();
      // }

      // if (textDisplay) {
      //   snackbar.show({ text: textDisplay, pos: "bottom-center" })
      // }
      // Push data to firebase
      return firebase
        .database()
        .ref(path)
        .update(data);
    },

    updateIntoFirebase(content, currentContent, previousState) {
      let updateState = [];
      console.log("content ", content)
      console.log("currentContent ", currentContent)
      let _this = this;
      // If no subscriber data, return
      if (!_this.subscriberId) return Promise.resolve();

      let data = {};


      if (content.inwatchlist !== undefined) {
        data.inwatchlist = content.inwatchlist;
      }

      if (content.likeDislikeDisplay) {
        data.likedislike = content.likeDislikeDisplay
      }
      if (content.watchedduration !== null && content.watchedduration !== undefined) {

        data.status = content.status ? content.status : "";
        data.watchedduration = data.status === "COMPLETED" ? 0 : content.watchedduration;
      }
      if (!previousState) data.startdate = dateToString(new Date());



      //  content.contenttype === "SERIES" && Episode update
      if (content.episodenum && content.seriesid && (previousState === undefined)) {

        let path = `${this.vendorId}/subscriber/${this.subscriberId}/series/${content.seriesid}/episode/${content.contentid}`;

        let data = {
          startdate:dateToString(new Date()),
          status :content.status ? content.status : "",
          watchedduration: content.status === "COMPLETED" ? 0 : content.watchedduration
        };
  
        return firebase
          .database()
          .ref(path)
          .update(data);

      }

      let pathContent;
      // if (content.contenttype === "SERIES") {
      if (content.seriesid) {
        pathContent = "series";
      } else {
        pathContent = "content";
      }

      // Define the path to add to watchlist
      let path = `${_this.vendorId}/subscriber/${_this.subscriberId}/${pathContent}/${content.seriesid ? content.seriesid : content.contentid
        }`;

      console.log("path ", path)
      console.log("data ", data)

      // Removes watchlist data from firebase

      updateState.push(firebase
        .database()
        .ref(path)
        .update(data));



      // let textDisplay = "";
      // if (content.watchListDisplay === "false") {
      //   textDisplay = "Removed from watchlist";
      // }
      // else if (content.watchListDisplay === "true") {
      //   textDisplay = "Added to watchlist";
      // }
      if (content.likeDislikeDisplay === "LIKE") {

        _this.vLiveSDKContentLikeDislike(
          content,
          1,
          previousState === "DISLIKE" ? -1 : 0
        );
        // this.actFetchLikeCount();
      }
      else if (content.likeDislikeDisplay === "DISLIKE") {

        _this.vLiveSDKContentLikeDislike(
          content,
          previousState === "LIKE" ? -1 : 0,
          1
        );
        // this.actFetchDislikeCount();
      }
      // else if (!content.watchedduration) {
      else if (content.likeDislikeDisplay === "NONE") {


        _this.vLiveSDKContentLikeDislike(
          content,
          previousState === "LIKE" ? -1 : 0,
          previousState === "DISLIKE" ? -1 : 0
        );
        // this.actFetchLikeCount();
        // this.actFetchDislikeCount();
      }

      // Show Removed from watchlist snackbar
      // if (textDisplay) {
      //   snackbar.show({ text: textDisplay, pos: "bottom-center" });
      // }

      // _this.firebaseStatus = false;
      return Promise.all(updateState);
    },
    fetchContentInfoFromFireBase(content) {
      return new Promise((resolve) => {
        let pathContent;
        if (content.contenttype === "SERIES") {
          pathContent = "series";
        } else {
          pathContent = "content";
        }
        let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}/${content.contentid}`;
        firebase
          .database()
          .ref(path)
          .on("value", snapshot => {
            resolve(snapshot.val())
          })
      });
    },


    fetchAllWatchlistContentFromFireBase(data) {
      return new Promise((resolve) => {
        let pathContent = "content";

        let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
        firebase
          .database()
          .ref(path)
          .on("value", snapshot => {
            let snapshotTemp = this.collectionsToArray(snapshot.val());
            let result = []
            if (data.watchlist) {

              snapshotTemp.forEach(element => {
                if (element.inwatchlist) {
                  result.push(element)
                }
              });
            }
            else if (data.continuewatching) {
              console.log("snapshot ", snapshotTemp)
              snapshotTemp.forEach(element => {
                if (element.status === "INPROGRESS" && element.watchedduration && element.duration - element.watchedduration > 0 && element.watchedduration >= 9) {
                  result.push(element)
                }
              });
            }
            resolve(result);

          })
      });
    },
    fetchAllWatchlistSeriesFromFireBase(data) {
      return new Promise((resolve) => {
        let pathContent = "series";

        let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
        firebase
          .database()
          .ref(path)
          .on("value", snapshot => {
            let snapshotTemp = this.collectionsToArray(snapshot.val());
            let result = []

            if (data.watchlist) {
              snapshotTemp.forEach(element => {
                if (element.inwatchlist) {
                  element.seriesid = element.contentid;
                  result.push(element)
                }
              });
            }
            else if (data.continuewatching) {
              // console.log("entered continue", snapshotTemp)
              snapshotTemp.forEach(element => {

                if (element.episode) {
                  for (const key of Object.keys(element.episode)) {

                    let ele = element.episode[key]

                    if (ele.watchedduration && ele.duration - ele.watchedduration >= 9 && ele.watchedduration >= 9) {
                      ele.seriesid = element.contentid;
                      ele.expiry = element.expiry;
                      ele.contentid = key;
                      // ele.seriesid = element.contentid
                      ele.startdate = ele.startdate ? ele.startdate : element.startdate;
                      result.push(ele)
                    }
                  }
                }
              });
            }
            resolve(result);
          })
      });
    },
    deleteFirebase(param) {
      return new Promise((resolve) => {
        let pathContent;
        let data = {}

        console.log("param ", param)

        // console.log("param ", param.frompage)
        if (param.frompage === "watchlist") {
          if (param.nodetype === "series") {
            pathContent = `series/${param.seriesid}`
          }
          else {
            pathContent = `content/${param.contentid}`
          }
          data.inwatchlist = false;
        }
        else if (param.frompage === "continuewatching") {

          if (param.nodetype === "series") {
            pathContent = `series/${param.seriesid}/episode/${param.contentid}`
          }
          else {
            pathContent = `content/${param.contentid}`
          }

          data.status = ""
          data.watchedduration = 0
          data.startdate = null;
        }
        let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
        console.log("path ", path)
        console.log("data ", data)
        firebase
          .database()
          .ref(path)
          .update(data, (err) => {
            if (err) console.log("data could not be removed from watchlist.", err);
            else resolve();
          });

      })
    },
    fetchCountFirebase(data) {
      let counttype=data.unique?'uniquecount':'count'
      return new Promise((resolve) => {
        let path = `${this.vendorId}/content/${data.contentid}/${data.type}/${counttype}`;
        firebase
          .database()
          .ref(path)
          .on("value", snapshot => {
            let snapshotTemp = snapshot.val();
            if (data.type === 'like') {
              // this.likeCount = snapshot.val()
            }
            else {
              // this.dislikeCount = snapshot.val();
            }
            resolve(snapshotTemp);
          })
      });
    },

    async activateSDK(){
      if (!window.vLiveWebSdk) {
        if (!window._activateSdk) return;
        else {
          await window._activateSdk();
        }
      }
    },

    async vLiveSDKContentLikeDislike(content, LikeCount, DislikeCount) {
      // If not able to get vLiveWebSdk instance, return
      await this.activateSDK();
      // Define the Analytics data
      let data = {
        EventCategory: "CONTENT",
        Event: "LikeDislike",
        ContentID: content.contentid,
        LikeCount: LikeCount,
        DislikeCount: DislikeCount,
        SubscriberID: this.subscriberId ? this.subscriberId : ""
      };
      // Push Data to SDK
      window.vLiveWebSdk.put(data);
    },

    async vLiveSDKContentView(content) {

      //this.isContentViewUpdated = true;

      // If not able to get vLiveWebSdk instance, return
      await this.activateSDK();
      // Define the Analytics data
      let data = {
        EventCategory: "CONTENT",
        Event: "contentview",
        ContentID: content.contentid,
        ViewCount: 1,
        EventTimeStamp: Math.floor(new Date().getTime() / 1000),
        SubscriberID: this.subscriberId ? this.subscriberId : "",
      };
      // Push Data to SDK
      window.vLiveWebSdk.put(data);
    },
    async vLiveSDKContentWatchTime(content, duration) {
      console.log("entered watched time")
      console.log("content ", content)
      console.log("duration ", duration)

      if (!content) return;
      if (!duration) return;

      // If not able to get vLiveWebSdk instance, return
      await this.activateSDK();
      // Define the Analytics data

      let data = {
        WatchingTime: duration,
        // EventTimeStamp: Math.floor(new Date().getTime() / 1000),
        ContentID: content.contentid,
        EventCategory: "CONTENT",
        Event: "contentwatchtime",
      };

      console.log("data ", data)
      // Push Data to SDK
      window.vLiveWebSdk.put(data);
    },
  },
  mixins: [appMixins]
};

export default FirebaseActions;